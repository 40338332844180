import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";

import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class ServerPolicy extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <DocumentTitle title="Terminos de Uso" />
        <h2>Términos de Uso</h2>
        <p></p>
        <p>OS Y CONDICIONES DEL SERVICIO</p>
        <p></p>
        <p>Pefectivo es un servicio de prestamos de dinero en línea de:</p>
        <p></p>
        <p>1. GENERAL</p>
        <p></p>
        <p>
          1.1. En lo sucesivo Pefectivo, declara por este medio que su página
          web, portal electrónico o portal de internet, la cual es una interface
          web disponible en teléfonos móviles y computadoras, en la aplicación
          “www” o World Wide Web o “Página Web” de Internet y/o un “App” (o
          aplicativo o aplicación) que se puede usar en teléfonos móviles o
          computadoras (en conjunto la Aplicación o Página Web indistintamente
          será denominada la “Página” o “Páginas”) se pueden encontrar en forma
          enunciativa más no limitativa, información, servicios, consultas,
          contenidos y/o productos preponderantemente de naturaleza crediticia.
        </p>
        <p></p>
        <p>
          1.2. LOS PRESENTES TÉRMINOS Y CONDICIONES GENERALES TIENEN UN CARÁCTER
          OBLIGATORIO Y VINCULANTE; TODO USUARIO DEBERÁ ABSTENERSE DE UTILIZAR
          EL SITIO Y/O LOS SERVICIOS EN CASO DE NO ACEPTARLOS. TODA APLICACIÓN
          Y/O GESTION DENTRO DEL SITIO SE ENTENDERÁ COMO LA ACEPTACIÓN EXPRESA
          DE LOS PRESENTES TÉRMINOS Y CONDICIONES:
        </p>
        <p></p>
        <p>
          1.3. El Usuario tiene la obligación de leer, entender y aceptar todas
          las condiciones establecidas en los Términos y Condiciones, en el
          Aviso de Privacidad y en los demás documentos análogos que impliquen
          una referencia directa o indirecta a los primeros, antes de
          registrarse como usuario o realizar cualquier gestión dentro de la
          Página.
        </p>
        <p></p>
        <p>2. USO Y RESTRICCIONES</p>
        <p></p>
        <p>
          2.1. Estos Términos y Condiciones regulan el acceso y utilización de
          la Página por parte del Usuario. La condición de Usuario es adquirida
          por la mera navegación, descarga y/o utilización de la Aplicación y/o
          página.
        </p>
        <p></p>
        <p>
          2.2. Estos Términos y Condiciones son aplicables a la oferta del
          servicio de prestamo, de consultoria crediticia y/o de protección de
          identidad (en adelante, los “Servicios” o los “Contenidos” o los
          “Servicios y Contenidos”) así como a la relación que se establece
          entre Pefectivo y el Usuario que utiliza los Servicios. Las
          características del servicio de financiamiento se regularán por medio
          de un contrato que es aceptado por el Usuario mediante indicadores
          electrónicos.
        </p>
        <p></p>
        <p>
          2.3. Los “Servicios y Contenidos” ofrecidos en la Página estarán
          únicamente disponibles para personas que gocen con capacidad legal
          para contratar, estarán expresamente excluidos quienes no cuenten con
          dicha capacidad así como los usuarios que hayan sido vetados
          definitiva o parcialmente por Pefectivo en relación a su
          comportamiento crediticio. En caso de solicitar los “Servicios y
          Contenidos” a cargo de una persona jurídica, se deberá hacer a través
          de su representante legal debidamente acreditado. En caso se requiera
          un tutor, curador o representante para la celebración de actos
          jurídicos, este deberá encontrarse debidamente acreditado.
        </p>
        <p></p>
        <p>
          2.4. Para solicitar los “Servicios y Contenidos”, el usuario estará
          obligado a completar el formulario con su información personal de
          manera precisa, verdadera y libre de vicios de mala fe, asumiendo el
          compromiso de monitorear y modificar sus datos personales cuando sea
          necesario. Pefectivo no se responsabilizará bajo ninguna circunstancia
          de la imprecisión de los datos personales del cliente, reservándose la
          facultad de requerir documentación comprobatoria.
        </p>
        <p></p>
        <p>
          2.5. Al utilizar la Página, el usuario se adhiere plenamente y sin
          reservas a los presentes Términos y Condiciones de uso y Privacidad. A
          través de la Página, el usuario tendrá acceso y/o disposición de
          servicios, productos y contenidos varios (en adelante “Servicios y
          Contenidos”). Pefectivo se reservará el derecho de negar o restringir
          en cualquier momento al usuario el acceso total o parcial a la “Página
          Web” cuando medie justa causa que comprometa la seguridad de las
          plataformas y/o procedimientos de la empresa. Pefectivo no garantiza
          en ningún momento la disponibilidad y continuidad de la operación,
          funcionalidad, utilidad y/o disponibilidad de la “Página Web” y/o los
          “Servicios y Contenidos”, en relación con ninguna actividad
          específica. Pefectivo no se responsabiliza por pérdida o daño alguno,
          sin importar su alcance o naturaleza, que resulte de la falta de
          continuidad o disponibilidad operativa o por cualquier daño ocurrido
          debido a la expectativa de continuidad de la “Página Web”, Aplicativo
          y/o de los “Servicios y Contenidos”.
        </p>
        <p></p>
        <p>
          2.6. Adicionalmente, la habilidad de Pefectivo de cumplir con los
          plazos y tiempos que ofrece al Usuario se basa en la correcta
          funcionalidad y disponibilidad de servicios externos a Pefectivo que
          pueden tener fallas que Pefectivo no puede controlar y hacer imposible
          que Pefectivo cumpla con los Servicios que ofrece en los tiempos
          pactados. Un incumplimiento de Pefectivo en los tiempos de aprobacion
          y entrega de prestamos, por responsabilidad de Pefectivo o de
          entidades externas, no releva a los Usuarios de cumplir con sus
          compromisos plenamente aceptados.
        </p>
        <p></p>
        <p>
          2.7. El Usuario también autoriza a Pefectivo a acceder a su
          dispositivo móvil, dandole permiso a Pefectivo a leer, cambiar,
          editar, encriptar (temporal o permanentemente) o modificar los
          contenidos de la memoria, usb, disco virtual, tarjeta SD, chip,
          sistema operativo, mensajes de texto o SMS del Usuario. Asimismo, el
          Usuario permite a Pefectivo enviar notificaciones al dispositivo,
          enviar mensajes o originar llamadas desde el dispositivo, y leer los
          registros de llamadas.
        </p>
        <p></p>
        <p>3. ACEPTACIÓN DE ESTOS TÉRMINOS Y CONDICIONES</p>
        <p></p>
        <p>
          3.1. El Usuario declara haber leído detenidamente -y comprendido-
          todos los términos establecidos en estos Términos y Condiciones y
          enmendados periódicamente por Pefectivo antes de descargar la
          Aplicación/App, o registrar una Cuenta con Pefectivo, que regirá el
          uso y el funcionamiento de la Aplicación/App y Cuenta.
        </p>
        <p></p>
        <p>
          3.2. Después de descargar la Aplicación, se considerará que acepta
          estos Términos y Condiciones al hacer clic en la opción “Aceptar” en
          nuestro sistema y le pedirá que confirme que ha leído, entendido y
          aceptado cumplir con estos Términos y Condiciones.
        </p>
        <p></p>
        <p>
          3.3. Al descargar la Aplicación y registrar una Cuenta, El Usuario
          acepta cumplir y estar sujeto a estos Términos y Condiciones que rigen
          el funcionamiento de la Cuenta y afirma que los Términos y Condiciones
          del presente documento son sin perjuicio de cualquier otro derecho que
          podamos tener con respecto a la cuenta legal o de otro tipo.
        </p>
        <p></p>
        <p>
          3.4. Estos Términos y Condiciones pueden ser modificados por Pefectivo
          de tiempo en tiempo y el uso continuado del Servicio constituye su
          aceptación de estar sujeto a los Términos y Condiciones de cualquier
          modificación o variación. Tomaremos todas las medidas razonables para
          notificarle cualquier cambio.
        </p>
        <p></p>
        <p>
          3.5. De tiempo en tiempo, se pueden emitir actualizaciones de la
          Aplicación y/o del Sitio web. Dependiendo de la actualización, es
          posible que no pueda usar el Servicio hasta que haya descargado o
          transmitido la última versión de la Aplicación y haya aceptado los
          nuevos términos y condiciones de estos Términos y Condiciones.
        </p>
        <p></p>
        <p>
          3.6. Al usar la Aplicación o cualquiera de los Servicios, El Usuario
          acepta que recopilemos y usemos información técnica sobre el
          Dispositivo Móvil y el software, hardware y periféricos relacionados
          para el Servicio que están basados en internet o inalámbricos para
          mejorar nuestros productos y proporcionar cualquier Servicio. Si
          utiliza estos Servicios, autoriza a Pefectivo y a nuestros Afiliados y
          licenciatarios a transmitir, recopilar, retener, mantener, procesar y
          usar Sus datos para determinar nuestros servicios de calificación
          crediticia o para mejorar nuestro Servicio y/o Su experiencia al usar
          el App.
        </p>
        <p></p>
        <p>
          3.7. El Usuario autoriza expresamente a Pefectivo, para que por
          conducto de nuestros funcionarios facultados llevemos a cabo
          investigaciones sobre su comportamiento crediticio en la Central de
          Riesgo que estimemos conveniente. Así mismo, declara que conoce la
          naturaleza y el alcance de la Información Personal que se solicitará,
          del uso que haremos de tal Información Personal y de que podremos
          realizar consultas periódicas de su Historial Crediticio, consintiendo
          que esta autorización se encuentre vigente por un período de 3 años
          contados a partir de la fecha de su expedición y en todo caso durante
          el tiempo que mantengamos relación jurídica.
        </p>
        <p></p>
        <p>
          3.8. También nos autoriza expresamente a contactar a El Usuario y a
          los contactos de emergencia (contacto para verificación) que haya
          declarado expresamente a la plataforma durante el proceso de solicitud
          de préstamo, para verificar su Información Personal, validar la
          identidad y la veracidad de la información de contacto declarada y/o
          cuando no podamos comunicarnos con El Usuario a través de todos los
          métodos regulares, y/o cuando no hayamos recibido el pago en relación
          con el Préstamo establecido en Cláusula 11 de este documento, en este
          ultimo escenario la comunicacion con el contacto de emergencia no será
          para informar de la deuda impaga, sino para validar que toda la
          informacion proporcionada por el usuario sea veridica y eficaz de
          manera tal que podamos restablecer el contacto con el usuario.
        </p>
        <p></p>
        <p>
          3.9. Es deber del Usuario el de entregar los contactos de emergencia
          necesarios para que Pefectivo pueda hacer verificaciones de identidad
          y/o validaciones de cualquier otra información entregada y/o poder
          establecer y/o restablecer comunicación con el Usuario, en caso de una
          falla en el contacto. Es obligacion del cliente mantener su
          información de contacto actualizada.
        </p>
        <p></p>
        <p>
          3.10. Pefectivo requerirá a el usuario la información de sus contactos
          de emergencia, mediate el uso de la aplicación que vincula la libreta
          de contactos del usuario con la plataforma de Pefectivo, en la que el
          usuario seleccionará voluntariamente de su lista de contactos a los
          frecuentes para ser sus contactos de emergencia, de manera tal que la
          plataformará realizará la validación correspondiente a fin de
          verificar la veracidad de la información proporcionada por el usuario.
          Siendo este un requisito indispensable para el otorgamiento del
          préstamo.
        </p>
        <p></p>
        <p>
          3.11. En caso el usuario autorice el débito automático y/o el cargo
          automático para el pago de las cuotas del crédito otorgado, Pefectivo
          queda autorizada a utilizar la información de la tarjeta de dèbito y/o
          de crédito que el usuario proporcione con este fin.
        </p>
        <p></p>
        <p>4. MODIFICACION DE LOS TERMINOS Y CONDICIONES</p>
        <p></p>
        <p>
          4.1. Pefectivo se reserva la facultad de modificar unilateralmente los
          Terminos y Condiciones, asi mismo como los “Servicios y Contenidos” de
          la Página en todo momento, y sera publicada oportunamente, siendo
          responsabilidad del Usuario revisar los Terminos y Condiciones
          vigentes al momento de la navegacion o solicitud de servicios.
        </p>
        <p></p>
        <p>
          4.2. En caso de que el Usuario no estuviera de acuerdo con las
          modificaciones mencionadas, podrá optar por no hacer uso de los
          Servicios ofrecidos a través de la Página.
        </p>
        <p></p>
        <p>5. PROPIEDAD INTELECTUAL</p>
        <p></p>
        <p>
          5.1. Todos los derechos de propiedad intelectual de la Aplicación y/o
          página y de sus contenidos y diseños pertenecen a Pefectivo o, en su
          caso, a terceras personas. En aquellos casos en que sean propiedad de
          terceros Pefectivo cuenta con las licencias necesarias para su
          utilización.
        </p>
        <p></p>
        <p>
          5.2. Quedan expresamente prohibidas la reproducción, distribución,
          transformación, comunicación pública y puesta a disposición, de la
          totalidad o parte de los contenidos de la Aplicación y/o página en
          cualquier soporte y por cualquier medio técnico, sin la autorización
          de Pefectivo. El Usuario se compromete a respetar los derechos de
          propiedad industrial e intelectual de titularidad de Pefectivo y de
          terceros.
        </p>
        <p></p>
        <p>
          5.3. Asimismo, queda expresamente prohibido la utilización o
          reproducción de cualquier marca registrada, nombre o logotipo que
          figure en la Aplicación y/o página sin la autorización previa y por
          escrito de Pefectivo, así como la utilización del software que esta
          opera con excepción de aquellos usos permitidos bajo estos Términos y
          Condiciones.
        </p>
        <p></p>
        <p>
          5.4. Pefectivo y “Pefectivo.tech” son Marcas Registradas que se
          encuentran protegidas por las normas de propiedad industrial tanto en
          el Perú como en el extranjero según las leyes aplicables. La
          utilización, difusión, exhibición, explotación, comercialización o
          cualquier otro uso, sean parcial o total, de forma idéntica o que
          confunda en menor o mayor grado; sea por cualquier medio, incluyendo
          mas no limitándose al impreso, magnético, óptico, electrónico o
          informático, está expresamente prohibido sin previa autorización por
          escrito del titular de los derechos de autor y/o marca
          correspondiente. Cualquier contravención a lo anteriormente expuesto o
          la legislación aplicable en materia de propiedad intelectual,
          industrial, derechos de autor será considerada y perseguida como un
          delito penal de comisión directa.
        </p>
        <p></p>
        <p>
          5.5. El logotipo, diseños, formas, marcas denominativas y mixtas,
          tanto de Pefectivo.tech como de Pefectivo, así como cualquier material
          estático o interactivo incluido en la Aplicación y/o página, está
          debidamente registrado ante las autoridades competentes y son
          propiedad de Pefectivo y/o de Pefectivo . Los derechos de propiedad
          intelectual correspondiente a los “Servicios y Contenidos” y los
          materiales distintivos y dominios de la Página, así como los derechos
          de uso y explotación correspondientes mismos que incluyen mas no se
          limitan a su publicación, reproducción, divulgación, transformación y
          distribución son propiedad exclusiva en conjunto de Pefectivo y de
          Pefectivo . El usuario no adquirirá bajo ninguna circunstancia
          concesión o derecho alguno sobre la propiedad intelectual e industrial
          de Pefectivo por el mero uso de su “Página Web” o de sus “Servicios y
          Contenidos”; por lo tanto, su uso no podrá ser considerado como una
          autorización tácita o expresa para utilizar los “Servicios y
          Contenidos” con algún fin diverso a los contemplados por el presente
          documento.
        </p>
        <p></p>
        <p>6. PROPIEDAD INTELECTUAL DE TERCEROS</p>
        <p></p>
        <p>
          El usuario reconoce y se obliga a los términos del apartado 5
          –PROPIEDAD INTELECTUAL, para efectos de la titularidad de los derechos
          de uso, difusión y explotación de los de terceros respecto a los
          servicios y contenidos ofrecidos en las páginas vinculadas a la
          Página.
        </p>
        <p></p>
        <p>7. USOS PERMITIDOS</p>
        <p></p>
        <p>
          7.1. El uso y aprovechamiento de los “Servicios y Contenidos” de la
          Página es exclusiva responsabilidad del usuario, quien en todo caso
          deberá utilizarlos únicamente de acuerdo a las funcionalidades
          permitidas en la Página y a los usos y/o prerrogativas autorizados
          expresamente por los presentes Términos y Condiciones de Uso y
          Privacidad, razón por la cual el usuario se obliga en todo momento a
          hacer uso de los mismos de modo tal que no atenten contra las normas
          de uso y convivencia en Internet, las leyes de la República del Perú y
          la legislación vigente en el país en que el usuario se encuentre al
          usarlos, las buenas costumbres, la dignidad de la persona y los
          derechos de terceros.
        </p>
        <p></p>
        <p>
          7.2. El usuario, mediante el ingreso de sus datos personales en la
          Página, autoriza expresamente a las entidades jurídicas que lo
          gestionen y/o su personal administrativo a derivar a partir de dichos
          otorgados, cualquier número o clave de identificación personal
          directamente vinculado con la prestación del servicio y con la
          verificación de la identidad del usuario. La Página es para el uso
          personal y del usuario por lo que no podrá comercializar de manera
          alguna los “Servicios y Contenidos” obtenidos de manera directa o
          indirecta.
        </p>
        <p></p>
        <p>7.3. Adicionalmente, el Usuario se compromete a:</p>
        <p></p>
        <p>
          7.3.1. No utilizar la Página con fines o efectos ilícitos o contrarios
          al contenido de los presentes Términos y Condiciones, que de cualquier
          forma pueda resultar en daño o daños a la plataforma o que impida el
          normal disfrute de la Página por otros Usuarios, o que puedan lesionar
          derechos.
        </p>
        <p></p>
        <p>
          7.3.2. No destruir, inutilizar, alterar o, de cualquier otra forma,
          dañar los programas, documentos o datos electrónicos y demás que se
          encuentren en la Página o registrados y/o almacenados por Pefectivo.
        </p>
        <p></p>
        <p>
          7.3.3. No obstaculizar el acceso a otros Usuarios mediante el consumo
          masivo de los recursos informáticos a través de los cuales Pefectivo
          presta servicios, ni realizar acciones que podrían dañar, interrumpir
          o generar errores en dichos servicios o sistemas.
        </p>
        <p></p>
        <p>
          7.3.4. No intentar penetrar o probar la vulnerabilidad de un sistema o
          de una red propia de la Página, así como quebrantar las medidas de
          seguridad o de autenticación del mismo.
        </p>
        <p></p>
        <p>
          7.3.5. Hacer adecuado uso de los contenidos que se ofrecen en la
          Página y a no emplearlos para incurrir en actividades ilícitas.
        </p>
        <p></p>
        <p>
          7.3.6. No utilizar la presente Página para enviar correos electrónicos
          masivos (más conocido como spam) o correos electrónicos con contenido
          hostil, ultrajante, vulgar, injurioso, amenazante, abusivo,
          difamatorio, u obsceno.
        </p>
        <p></p>
        <p>
          7.3.7. Aceptar que debido a problemas técnicos, tráfico, horarios, o
          razones ajenas al control de Pefectivo, los Servicios podrán demorarse
          o interrumpir indefinidamente en cuyo caso, Pefectivo tendrá la opción
          de enviar una comunicación señalando la fecha real de desembolso de
          crédito y se realizará el cómputo del plazo correspondiente, y siempre
          y cuando ocurra dentro de 72 horas de haber sido aprobada la solicitud
          de préstamo, el Usuario se compromete a aceptar el préstamo y pagarlo
          según las condiciones pactadas en el contrato.
        </p>
        <p></p>
        <p>
          7.3.8. Aceptar que debido a interrupción o funcionamiento defectuoso
          de las instalaciones, equipos, o canales de Pefectivo; podría existir
          algún error en las transacciones efectuadas, así como demora debido a
          problemas técnicos, tráfico y horarios, por lo que exime de
          responsabilidad a Pefectivo cuando el hecho haya ocurrido por causas
          no imputables a éste y se compromete a comunicar dichos hechos en
          cuanto tenga conocimiento de estos.
        </p>
        <p></p>
        <p>
          7.3.9. Advertir y comunicar cualquier tipo de error o inexactitud que
          el Usuario advierta respecto a alguna comunicación de Pefectivo, y
          esta comunicación deberá ser efectuada en cuanto tenga conocimiento de
          ésta o dentro de 24 horas en circunstancias extremas. Una vez
          reportado el hecho a Pefectivo, este será atendido según los controles
          internos establecidos.
        </p>
        <p></p>
        <p>
          7.4. En el caso en que un Usuario infrinja lo establecido en el
          presente apartado, Pefectivo podrá proceder a realizar alguna de las
          siguientes acciones, dependiendo de la gravedad o reiteración de la
          infracción:
        </p>
        <p></p>
        <p>
          7.4.1. Acciones por responsabilidad civil, administrativa o penal.
        </p>
        <p></p>
        <p>7.4.2. Cancelación definitiva de la cuenta del Usuario.</p>
        <p></p>
        <p>7.4.3. Amonestación al Usuario</p>
        <p></p>
        <p>7.4.4. Suspensión temporal de la cuenta del Usuario.</p>
        <p></p>
        <p>8. PROHIBICIONES</p>
        <p></p>
        <p>
          8.1. El usuario no contará en ningún momento con el derecho de colocar
          hipervínculos de sitios web a la Aplicación y/o página, ni el derecho
          de utilizar los “Servicios y Contenidos” de la Aplicación y/o página
          sea directa o indirectamente en sitios o páginas propias o de terceros
          sin autorización expresa, previa y por escrito del representante legal
          de Pefectivo. Asimismo, el usuario no tendrá el derecho de limitar o
          impedir a cualquier otro usuario el uso de la Aplicación y/o página.
          El usuario tendrá prohibida la venta, cesión o transferencia total o
          parcial de la Cuenta bajo ningún título.
        </p>
        <p></p>
        <p>
          8.2. El usuario tendrá estrictamente prohibido interferir directa o
          indirectamente con las operaciones, bases de datos actividades y/o
          contenidos de la Aplicación y/o página o Pefectivo. El no observar las
          presentes limitaciones generará responsabilidad directa en el usuario
          de acuerdo a la legislación aplicable y le obligará a resarcir los
          daños y perjuicios causados por sus actos y/u omisiones.
        </p>
        <p></p>
        <p>9. CALIDAD DE LOS SERVICIOS Y CONTENIDOS.</p>
        <p></p>
        <p>
          9.1. Pefectivo ni sus proveedores o socios comerciales serán
          responsables de cualquier daño o perjuicio que sufra el usuario a
          consecuencia de inexactitudes, errores tipográficos, técnicos y
          cambios o mejoras que se realicen periódicamente a los “Servicios y
          Contenidos”. Las recomendaciones y consejos obtenidos a través de la
          Página son de naturaleza general, por lo que no deben tomarse en
          cuenta en la adopción de decisiones personales. El Usuario deberá
          consultar en cualquier evento a un profesional que pueda brindarle
          asesoría oportuna de acuerdo con sus necesidades específicas.
        </p>
        <p></p>
        <p>
          9.2. Pefectivo en ciertas ocasiones podrá incluir enlaces a páginas
          externas a{" "}
        </p>
        <p>
          https://Pefectivo.tech/ con el fin de informar al Usuario de servicios
          adicionales o proporcionarles información que, en el criterio de
          Pefectivo, pueda resultar relevante para el Usuario. Pefectivo no
          aceptará responsabilidades por la utilización de estos enlaces ni
          garantizará la veracidad, fiabilidad, calidad, o relevancia de la
          información contenida en estos enlaces.
        </p>
        <p></p>
        <p>10. CONFIDENCIALIDAD</p>
        <p></p>
        <p>
          10.1. Pefectivo se obliga expresamente a mantener confidencial la
          información que reciba del usuario que tenga dicho carácter conforme a
          legislación aplicable a la materia en la República del Perú. Pefectivo
          no se obliga a guardar confidencialidad respecto a cualquier otra
          información señalada por el usuario durante cualquier interacción con
          la Página o en el proceso de aplicación y/o obtención de los
          “Servicios y Contenidos”, incluyendo mas no limitándose a la
          información proporcionada en blogs, chats o medios afines.
        </p>
        <p></p>
        <p>11. USO DE LA INFORMACIÓN NO CONFIDENCIAL</p>
        <p></p>
        <p>
          El usuario autoriza expresamente a Pefectivo a usar, publicar, o
          comunicar públicamente la información no considerada confidencial
          ingresada mediante el uso de la Página, en términos de lo establecido
          en la Ley sobre el Derecho de Autor, Decreto Legislativo N° 822.
        </p>
        <p></p>
        <p>12. RESPONSABILIDADES DE LOS USUARIOS</p>
        <p></p>
        <p>
          12.1. El Usuario podrá navegar y acceder a la Página sin necesidad de
          registrarse. Igualmente el Usuario acepta registrarse para acceder a
          los servicios que requieran la identificación del Usuario.
        </p>
        <p></p>
        <p>
          12.2. El Usuario es la única persona responsable de conservar el
          número de sus cuentas, contraseñas personales, claves de acceso y
          números confidenciales con los cuales tenga acceso a los “Servicios y
          Contenidos” de la Página en estricto secreto. Previo a la utilización
          los servicios que ofrecidos en la Página, el usuario deberá de leer y
          comprender los Términos y Condiciones y Privacidad, mismos en los
          cuales se describirán a detalle los aspectos de uso. El usuario deberá
          responsabilizarse por la confidencialidad de su contraseña, contrato
          de crédito simple, datos bancarios e información personal.
        </p>
        <p></p>
        <p>
          12.3. Pefectivo no se hará responsable en ninguna ocasión por errores
          generales o tipográficos en los cuales incurra el usuario o por
          negligencia al usar los servicios de la Página. Pefectivo se exime de
          responsabilidad ante:
        </p>
        <p></p>
        <p>
          12.3.1. Errores tipográficos, de captura o por el uso indebido del
          contenido de la “Página Web”.
        </p>
        <p></p>
        <p>
          12.3.2. Negligencia en el cuidado de sus credenciales de acceso y
          contraseña.
        </p>
        <p></p>
        <p>
          12.3.3. Negligencia al dejar cualquier dispositivo electrónico,
          mediante el cual tenga acceso a la Página y sus servicios, sin
          atención en cualquier momento.
        </p>
        <p></p>
        <p>13. COOKIES</p>
        <p></p>
        <p>
          13.1. El usuario que tenga acceso a la Página, acuerda recibir las
          Cookies que les transmitan los servidores de Pefectivo. “Cookie”
          significa un archivo de datos que se almacena en el disco duro de la
          computadora del usuario cuando este tiene acceso a la Página. Las
          Cookies pueden contener información tal como la identificación
          proporcionada por el usuario o información para rastrear las páginas
          que el usuario ha visitado. Una Cookie no puede leer los datos o
          información del disco duro del usuario ni leer las Cookies creadas por
          otros sitios o páginas.
        </p>
        <p></p>
        <p>
          13.2. Los presentes Términos y Condiciones de uso y Privacidad atañen
          únicamente a los servicios de Pefectivo. No tenemos control alguno
          sobre los sitios web mostrados como resultados de búsquedas o enlaces
          que se incluyen en nuestra Página. Es posible que estos sitios
          independientes envíen sus propias cookies u otros archivos a su
          equipo, recopilen datos o le soliciten que envíe información personal.
        </p>
        <p></p>
        <p>14. MODIFICACIONES</p>
        <p></p>
        <p>
          Pefectivo tendrá el derecho de modificar en cualquier momento los
          Términos y Condiciones de uso y Privacidad, de la Página. En
          consecuencia, el usuario debe leer atentamente los Términos y
          Condiciones de uso y Privacidad, de la Página cada vez que pretenda
          utilizar la Página.
        </p>
        <p></p>
        <p>15. GENERALES</p>
        <p></p>
        <p>
          El incumplimiento por parte de los Usuarios, usuarios y/o navegantes
          de los presentes términos y condiciones, así como los reglamentos,
          disposiciones e interfaces relacionadas dará como consecuencia la
          suspensión del perfil y las gestiones del usuario, sin perjuicio de
          las responsabilidades y/o sanciones descritas en la legislación
          aplicable. La inobservancia e incumplimiento de las obligaciones
          crediticias por parte del usuario será oportunamente comunicada a las
          instituciones de información crediticia.
        </p>
        <p></p>
        <p>16. COMUNICACIONES</p>
        <p></p>
        <p>
          Las notificaciones y comunicaciones de Pefectivo con el cliente con
          ocasión de la solicitud y contrato del crédito podrán ser realizadas:
        </p>
        <p></p>
        <p>
          16.0.1. por medio de un comunicado publicado en el portal web de
          Pefectivo.
        </p>
        <p></p>
        <p>
          16.0.2. un mensaje enviado desde y hacia el correo electrónico
          hola@Pefectivo.tech
        </p>
        <p></p>
        <p>16.0.3. un mensaje o llamada al teléfono del cliente;</p>
        <p></p>
        <p>
          16.0.4. un mensaje dado a conocer a través cualquier medio electrónico
          que Pefectivo utilice para celebrar operaciones;
        </p>
        <p></p>
        <p>
          16.0.5. o bien a través del sitio de internet de Pefectivo:
          https://www.Pefectivo.tech/.
        </p>
        <p></p>
        <p>17. MEDIOS Y DOCUMENTOS ELECTRÓNICOS</p>
        <p></p>
        <p>
          17.1. Los servicios crediticios provistos por Pefectivo son en su
          mayoría en línea o contratados por via internet. Por lo tanto, al
          aceptar los Términos y Condiciones, las partes expresamente consienten
          y aceptan a su vez que los acuerdos y comunicaciones entre ambos sean
          a través de cualquier medio electrónico que Pefectivo utilice para
          celebrar operaciones.
        </p>
        <p></p>
        <p>
          17.2. Asimismo, las partes aceptan que estarán legalmente obligadas a
          cumplir los acuerdos firmados electrónicamente en virtud de lo
          establecido en el artículo 1 de la Ley N° 27269 sobre Firmas y
          Certificados Digitales, (2000). De este modo, las firmas electrónicas
          que aparecen en la hoja resumen, el contrato de crédito y el pagaré
          enviados vía correo electrónico, a efectos legales, tienen la misma
          validez y eficacia jurídica que el uso de una firma manuscrita.
        </p>
        <p></p>
        <p>
          17.3. El Usuario declara y acepta que la dirección de correo
          electrónico que proporciono a Pefectivo es una dirección de correo
          válida que el Usuario revisa regularmente para estar enterado de su
          situación contractual con Pefectivo y recibir todo tipo de
          notificaciones.
        </p>
        <p></p>
        <p>
          El Usuario declara y acepta que el número de teléfono que proporciono
          a Pefectivo es un número valido que el Usuario revisa regularmente
          para estar enterado para recibir mensajes y llamadas de Pefectivo que
          pueden relacionarse con su situación contractual con Pefectivo y
          recibir todo tipo de notificaciones.
        </p>
        <p></p>
        <p>
          17.4. Toda comunicación que el Usuario quiera realizar con Pefectivo
          deberá ser utilizando la dirección de correo electrónica proporcionada
          aquí: hola@Pefectivo.tech
        </p>
        <p></p>
        <p>18. AUTORIDADES Y REQUERIMIENTOS LEGALES</p>
        <p></p>
        <p>
          18.1. Pefectivo se compromete a resguardar la información del Usuario
          siempre y cuando esta no sea solicitada por autoridades competentes
          para garantizar el cumplimento de las leyes en materia de protección
          de derechos de protección intelectual e industrial, prevención del
          fraude, la protección del consumidores y otras materias.
        </p>
        <p></p>
        <p>
          18.2. Asimismo, Pefectivo puede optar por utilizar y/o difundir
          información de Usuarios que aparezcan, puedan, o estén en violación de
          estos Terminos y Condiciones para la protección de la Página,
          Pefectivo, y otros Usuarios. Pefectivo está facultada a tomar estas
          decisiones sin notificar a algún Usuario afectado por la utilización
          y/o difusión de sus datos.
        </p>
        <p></p>
        <p>19. INEXISTENCIA DE SOCIEDAD O RELACION LABORAL</p>
        <p></p>
        <p>
          La participación de un Usuario en el Portal no constituye ni crea
          contrato de sociedad, de representación, de mandato, como así tampoco
          relación laboral alguna entre dicho Usuario y Pefectivo.
        </p>
        <p></p>
        <p>20. CESION DE POSICION CONTRACTUAL</p>
        <p></p>
        <p>
          Los Usuarios expresamente autorizan la cesión de estos Términos y
          Condiciones y de su información personal en favor de cualquier persona
          que (i) quede obligada por estos Términos y Condiciones y/o (ii) que
          sea el nuevo responsable del banco de datos que contenga su
          información personal. Luego de producida la cesión, Pefectivo no
          tendrá ninguna responsabilidad con respecto de cualquier hecho que
          ocurra a partir de la fecha de la cesión. El nuevo responsable del
          banco de datos asumirá todas y cada una de las obligaciones de
          Pefectivo establecidas en los presentes Términos y Condiciones, y en
          la Política de Privacidad respecto del tratamiento, resguardo y
          conservación de la información personal de los usuarios del Portal.
        </p>
        <p></p>
        <p>21. FUERZA MAYOR</p>
        <p></p>
        <p>
          Pefectivo no es ni será responsable por retraso alguno, o falla, en el
          cumplimiento de sus obligaciones o interrupción de sus operaciones, o
          prestaciones de servicio, que pueda resultar directamente o
          indirectamente de causas que puedan estar razonablemente fuera de su
          control, en general aquellas denominadas causas de fuerza mayor o
          actos imprevisibles.
        </p>
        <p></p>
        <p>22. LEYES APLICABLES Y JURISDICCIÓN</p>
        <p></p>
        <p>
          ara la interpretación, cumplimiento y ejecución del presente contrato,
          las partes están de acuerdo en que serán aplicables las leyes de la
          República del Perú, sometiéndose a la jurisdicción y competencia de
          los juzgados, salas y/o tribunales del Cercado de Lima, renunciando
          expresamente a cualquier otro fuero o jurisdicción que pudiera
          corresponderles en razón de sus domicilios presentes o futuros.
        </p>
      </div>
    );
  }
}
export default ServerPolicy;
