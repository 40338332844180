import React from 'react';
import PropTypes from 'prop-types';
// import { Menu, Popover } from 'antd';

import { enquireScreen } from 'enquire-js';


class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    activefaq: PropTypes.bool,
    activeProduct: PropTypes.bool,
    activeHome: PropTypes.bool,
    handleclick: PropTypes.func,
  }

  state = {
    menuVisible: false,
    menuMode: 'horizontal',
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ menuMode: b ? 'inline' : 'horizontal' });
    });
  }

  handleShowMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible});
  }


  handleMenuClick = (event) => {
    this.props.handleclick(event.key);
    this.setState({ menuVisible: false});
  }

  render() {
 
    const {  isMobile } = this.props;
 

    return (
    <header {...this.props} >
      <div className={ `${isMobile ? 'headerWrapper-mobile':'headerWrapper'} ${this.props.className}`}>
      </div>
    </header>
    );
  }
}

export default Header;
