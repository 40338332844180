import React from "react";
import { HashRouter as Router, Link } from "react-router-dom";
import emailMobile from "../assets/imgs/mobile/email.png";
function Footer(props) {
  if (props.isMobile) {
    return (
      <footer id="footer" className="footer-mobile">
        <div className="one-center">
          <div className="title">Regulaciones</div>

          <Router>
            <div className="infos">
              <Link to="/privacidad" target="_blank">
                《Acuerdo de Privacidad》
              </Link>
            </div>
            <div className="infos">
              <Link to="/servicio" target="_blank">
                《Acuerdo de Servicio》
              </Link>
            </div>
          </Router>
        </div>
        <div className="one-center-end">
          <div className="title">Pefectivo</div>
          <div className="infos">
            <img src={emailMobile} className="contImg" alt="fottOne" />
            <div>service@Pefectivo.com</div>
          </div>
        </div>
      </footer>
    );
  }
  return (
    <footer id="footer" className={"footer"}>
      
      <div className="bottom-bar">
        Copyright © 2024 | Pefectivo | All Rights Reserved
      </div>
    </footer>
  );
}

export default Footer;
