import React from "react";
import PropTypes from "prop-types";
import { Element } from "rc-scroll-anim";
import downLoad from "../assets/imgs/downLoad.png";
import bannerImg from "../assets/imgs/bannerImg.png";
import bannerImgMobile from "../assets/imgs/mobile/bannerImg.png";
// import title from '../assets/imgs/title.png';
import logoBg from "../assets/imgs/logo.png";
import email from "../assets/imgs/email.png";
import { BrowserRouter as Router, Link } from "react-router-dom";

class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
  };
  static defaultProps = {
    className: "banner",
  };
  constructor(props) {
    super(props);
    this.state = {
      appUrl: "",
    };
  }
  componentDidMount() {
    console.log(window.location.origin);
    fetch(window.location.origin + "/api/download/page/config?hash=13", {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      mode: "no-cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.ret === 0) {
          const { data = {} } = res;
          this.setState({
            appUrl: data.url,
          });
        }
      });
  }

  render() {
    const { className, navToShadow, isMobile } = this.props;
    const { appUrl, andro } = this.state;
    return (
      <Element
        id="home"
        component="section"
        className={isMobile ? "block-mobile " : ""}
        onChange={navToShadow}
      >
        <div className={className + (isMobile ? "-mobile " : "")}>
          <div className={"headerBox"}>
            <div className={"headerLeft"} />
            <div className={"headerRight"} />
          </div>

          <div className={`content`}>
            <div className="logoBox">
              <img src={logoBg} className="logo" alt="logo" />
            </div>
            {/* <div className={`title`}>{'¡Le Ayudamos A Obtener Un Préstamo Más Fácilmente!'}</div> */}
            <div className={`title`}>
              {
                "PRÉSTAMO SIMPLE Y RÁPIDO,"
              }
              <br/>
              {
                "LE AYUDARÁ A RESOLVER EL PROBLEMA"
              }
            </div>
            <div className={`subtitle`}>
              {
                "En sólo tres pasos, ¡puede obtener un préstamo en tan sólo 2-24 horas!"
              }
            </div>
            <div className={`desc`}>
              {"Registro -> Evaluación -> Desembolso"}
            </div>

            <div className={`imgWrapper`}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={andro}
                key="a"
                className={`word-download`}
              >
                <button className="banner-btn">
                  <a
                    target="_blank"
                    href={appUrl}
                    key="a"
                    rel="noopener noreferrer"
                  >
                    <img className="banner-img" src={downLoad} alt="downLoad" />
                    Descargar Pefectivo{" "}
                  </a>
                </button>
              </a>
                <img src={isMobile ? bannerImgMobile : bannerImg} alt="img" />
            </div>

            
            {!isMobile ? <div className="bar">
              <div className="one-center">
                <div className="title">Contáctanos</div>
                <div className="infos">
                  <img src={email} className="contImg" alt="fottOne" />
                  <div>service@Pefectivo.com</div>
                </div>
              </div>
              <div className="one-center one-center-end">
                <div className="title">Regulaciones</div>
                <Router>
                  <div className="infosBox">
                  <div className="infos">
                    <Link to="/privacidad" target="_blank">
                      {'< Acuerdo de Privacidad >'}
                    </Link>
                  </div>
                  <div className="infos">
                    <Link to="/servicio" target="_blank">
                      {'< Acuerdo de Servicio >'}
                    </Link>
                  </div>
                  </div>
                </Router>
              </div>
            </div> : null}
          </div>
          <div className={"headerBox"}>
            <div className={"headerLeft"} />
            <div className={"headerRight"} />
          </div>
        </div>
      </Element>
    );
  }
}

export default Banner;
