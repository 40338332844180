import React, { Component } from 'react';
import Home from './Home';
import privacyPolicy from './Page/privacidad';
import serverPolicy from './Page/servicio';
import ErrorInfo from './Page/ErrorInfo';
import removeDatas from './Page/removeDatas';
import { Router, Route } from "react-router-dom";
import {createBrowserHistory} from 'history'
const browserHistory = createBrowserHistory()
class App extends Component {
  render() {
    return (
      <Router history={browserHistory}>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacidad" component={privacyPolicy} />
        <Route exact path="/servicio" component={serverPolicy} />
        <Route exact path="/errorInfo" component={ErrorInfo} />
        <Route exact path="/removeDatas" component={removeDatas} />
      </Router>
    );
  }
}

export default App;
